<template>
  <div class="px-2 mt-1">
    <header-slot>
      <template #actions>
        <portal-target name="settings-actions" />
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'management-settings-general' }"
        exact
        exact-active-class="active"
        :link-classes="[
          this.$route.meta.pageTitle == 'General' ? 'active' : '',
          'px-3',
          bgTabsNavs,
        ]"
      >
        GENERAL
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'payment-settings' }"
        exact
        exact-active-class="active"
        :link-classes="[
          this.$route.meta.pageTitle == 'Payments' ? 'active' : '',
          'px-3',
          bgTabsNavs,
        ]"
      >
        PAYMENTS
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'ring-central-settings' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        RING CENTRAL
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'management-settings-dynamic-forms' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        CUSTOM FIELDS
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'management-settings-availability' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        SETTINGS MEETINGS
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'management-users-availability' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        USERS FOR CUSTOMER TICKETS
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
      id="tab-settings"
    >
      <router-view />
    </b-card>
  </div>
</template>

<script>
export default {
  methods: {
    isMangementDynamicForms() {
      let element = document.getElementById("tab-settings");
      if (this.$route.name === "management-settings-dynamic-forms") {
        element.classList.remove("card");
      } else {
        element.classList.add("card");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.isMangementDynamicForms();
    },
  },
  mounted() {
    this.isMangementDynamicForms();
  },
};
</script>
